<template>
  <!-- 标题组 -->
  <div>
    <el-card>
      <el-container class="cards">
        <!-- 左侧容器 -->
        <el-aside>
          <div class="left-main">
            <div>
              <div class="flex">
                <el-input
                  placeholder="请输入标题内容"
                  v-model="searchTitleName"
                  clearable
                >
                </el-input>
                <el-button
                  icon="el-icon-search"
                  @click="selectGroupTitleFun()"
                ></el-button>
              </div>
            </div>
            <!-- 标题组列 -->
            <div class="nameDiv-y">
              <div
                :class="
                  groupTitleId == item.id
                    ? 'v-for-div-color v-for-div flex'
                    : 'v-for-div flex'
                "
                v-for="(item, index) in groupTitle"
                @click="selectGroupTitleSucaiFun(item.id)"
              >
                <div class="nameDiv flex">
                  <div>({{ item.count }})</div>
                  <div>{{ item.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </el-aside>
        <!-- 主要容器 -->
        <el-main>
          <div class="mainContainer">
            <!-- 视频素材集合 -->
            <div class="videoListdiv flex">
              <el-checkbox-group
                v-model="checkedCities"
                @change="getSucaiFun()"
              >
                <el-checkbox
                  v-for="(item,index) in groupTitleSucai"
                  :label="item"
                  :key="item"
                  :max="2"
                >
                  <el-card :style="item.type==1?'background-color: #A1F6A3':'background-color: #55BDF8'" >
                    <div class="video-card">
                      <div class="imgv">
                        <img :src="item.imageUrl" width="115" height="180" />
                        
                      </div>
                      <div><i>{{item.materialId}}</i></div>
                      <div><i>编号：{{index+1}}</i></div>
                    </div>
                  </el-card>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-card>
  </div>
</template>

<script>
// 分页组件
import pagination from '@/components/pagination.vue'

import { ElMessage } from 'element-plus'
import { inject, reactive, toRefs, watch, defineComponent } from 'vue'
import { login } from '@/request'
import Image from '@/components/image.vue'
import { stringify } from 'qs'

export default {
  name: 'clickVideo',
  components: { pagination, Image },
  // 接收页面的传值
  props: ['groupTitle', 'groupTitleSucai', 'changeSucai'],
  setup(props, context) {
    const state = reactive({
      // 选中的素材数据
      checkedCities: [],
      // 改变之后的素材数据
      // changeSucai: props.changeSucai,
      //标题组数据
      groupTitle: props.groupTitle,
      //组内素材数据
      groupTitleSucai: props.groupTitleSucai,
      //搜索的名称
      searchTitleName: '',
      //搜索方法
      selectGroupTitleFun: () => {
        const data = {
          searchTitleName: state.searchTitleName,
        }
        context.emit('getTitleGroupFun', data)
      },
      //查询素材
      selectGroupTitleSucaiFun: (val) => {
        // // 清空数据
        // state.checkedCities = []
        const data = {
          tid: val,
        }
        context.emit('getTitleGroupIDFun', data)
        console.log('state.groupTitleSucai', state.groupTitleSucai)
      },

      // 素材值更新
      getSucaiFun: () => {
        console.log('素材值更新getSucaiFun', state.checkedCities)
        context.emit('getSucaiFun', state.checkedCities)
      },
      //清空全部数据
      clickDelAlldata:()=>{
        state.checkedCities = []
      }
    })
    //监听删除选中素材
    watch(
      () => state.changeSucai,
      (newVal, OldVal) => {
        console.log('【点击视频组件】选中素材改变了，老值cv', OldVal)
        console.log('【点击视频组件】选中素材改变了,新值cv', newVal)
        state.checkedCities = newVal
      }
    )

    return {
      // 结构赋值，暴露出去就不用 state.xx
      ...toRefs(state),
    }
  },
}
</script>

<style lang="scss" scoped>
// .top {
//   height: 400px;
// }

.cards {
  height: 600px;
}

.el-main {
  --el-main-padding: 18px;
}

.el-aside {
  width: 18%;
}

.add {
  div {
    padding-right: 50px;
  }
}

.left-main {
  > div {
    margin-bottom: 10px;
  }
}

.nameDiv-y {
  // overflow-y: auto;
}

.addTitleBtn {
  justify-content: flex-end;
  > div {
    margin-left: 40px;
  }
}

.mainBtn {
  margin-bottom: 10px;
  margin-top: 10px;
  > div {
    margin-right: 40px;
  }
}
.title-tbn {
  height: 40px;
  line-height: 40px;
  // width: 30%;
  > div {
    padding-right: 4px;
  }
}
.nameDiv {
  // border: 2px solid rgb(220, 223, 230);
  // width: 72%;
  justify-content: space-between;
  margin-right: 10px;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-for-div {
  align-content: center;
  border: 1px solid rgb(220, 223, 230);
  cursor: pointer;
  height: 40px;
  padding-left: 4px;
  box-sizing: border-box;
  border-radius: 1px;
  // > div {
  //   margin-top: 2px;
  // }
}

.v-for-div-color {
  background-color: rgb(102, 177, 255);
}

.v-for-div:hover {
  border: 1px solid rgb(102, 177, 255);
}
.tableBtn {
  > div {
    margin-bottom: 40%;
  }
}

.v-for-videoList {
  // width: 270px;
  // height: 480px;
  // margin-right: 10px;
  width: 160px;
  height: 240px;
  margin-right: 10px;
}

.videoListdiv {
  flex-wrap: wrap;
  overflow-y: auto;
}

.video-card {
  > div {
  //  margin-bottom: 10px;
  }
}

.imgv {
  cursor: pointer;
}

.avatar-uploader-icon {
  display: block;
  font-size: 180px;
}

.tips {
  margin-top: 10px;
  color: rgb(245, 8, 8);
}

.textBtn {
  justify-content: space-between;
}

.Ftree {
  margin-right: 30px;
}

.downBtn {
  justify-content: flex-end;
  padding: 10px 10px 0px 15px;
  > div {
    padding-left: 30px;
  }
}


</style>
